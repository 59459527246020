import React from 'react';
import classnames from 'classnames';
import {Card, CardBody, CardTitle, Table, Button, Modal, Nav, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Row, Col, NavItem, NavLink, InputGroup} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { rtdb, functions } from '../components/firebase/firebase';

@inject('observableStore')
@observer
class Bestellingen extends React.Component {	
render() {
	return (
		<div className="mainDiv">
			{this.state.loaded ?
			<div>
				<Row>			
				{this.state.orders.map((order, index) => (	
					<Col key={index} lg={4} style={{marginBottom: 30}}>					
						<Card className="order-display">
							<div style={{width: '100%', backgroundColor: order.klaar ? '#43a047' : '#d12a5e', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 12, paddingLeft: 15, paddingRight: 15}}>
								<div>
									<div style={{color: '#fff', letterSpacing: 1, marginTop: -2, fontSize: 17}}><span>#{order.id}</span></div>
									<div style={{color: '#fff', letterSpacing: 1, marginTop: 1, fontSize: 14}}><span>{this.formatTime(index)} - {order.name}</span></div>
								</div>
								<div style={{display: 'flex'}}>
									<div style={{cursor: 'pointer', padding: 10,}} onClick={() => this.toggleMsgModal(index, order.user, order.id, 0)}>
										{order.notified && order.notified > 0 ?
											<div style={{position: 'absolute', backgroundColor: order.klaar ? '#43a047' : '#d12a5e', top: 17, marginLeft: 8, height: 15, width: 15, borderRadius: 15/2, borderWidth: 1, borderColor: '#fff', borderStyle: 'solid', fontSize: 9, color: '#fff', textAlign: 'center', paddingTop: 1}}><span>{order.notified}</span></div>
										:null}
										<i class="fa-regular fa-bell-on" style={{fontSize: 21, color: '#fff'}}></i>
									</div>
									<div style={{cursor: 'pointer', padding: 10, marginLeft: 5}} onClick={() => this.toggleDelModal(order.id)}><i class="fa-regular fa-trash-list" style={{fontSize: 21, color: '#fff'}}></i></div>
								</div>
							</div>
							<div style={{width: '100%', padding: 25, paddingBottom: 10, paddingTop: 10}}>
								{order.items.map((item, index2) => (	
									<div key={index2} style={{position: 'relative', paddingTop: 15, paddingBottom: 15, alignItems: 'center', borderWidth: 0, borderBottomWidth: index2 +1 === order.items.length ? 0 : 1, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.1)'}}>
										<div onClick={() => this.markItem(index, index2)} style={{width: '100%', cursor: 'pointer'}}>
											<div style={{textDecorationLine: item.marked ? 'line-through' : 'none', letterSpacing: 1, marginTop: -2, fontSize: 18}}><span>{item.aantal}x {item.naam}</span></div>
											<div style={{textDecorationLine: item.marked ? 'line-through' : 'none', letterSpacing: 1, marginTop: -2, fontSize: 14}}><span>{item.notitie ? item.notitie : 'Geen notitie'}</span></div>
										</div>
										<div onClick={() => this.toggleNoteModal(index, index2)} style={{position: 'absolute', right: -25, top: 0, height: '100%', cursor: 'pointer', width: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
											<i class="fa-light fa-pen-to-square" style={{fontSize: 18}}></i>
										</div>
									</div>
								))}
							</div>
						</Card>					
					</Col>
				))}
				</Row>	
				{this.state.orders.length === 0 ?
				<Row style={{marginTop: 25}}>
					<Col lg={3}></Col>
					<Col lg={6}>					
						<Card className="order-display" style={{padding: 50}}>
							<div style={{textAlign: 'center', letterSpacing: 1, marginTop: -2, fontSize: 14}}><span>Er zijn geen bestellingen op dit moment</span></div>
						</Card>					
					</Col>
					<Col lg={3}></Col>
				</Row>
				:null}
				</div>
			:
			<div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', paddingTop: 100}}>
				<div class="loading-spinner" style={{width: 85, height: 85, borderWidth: 5}}></div>
			</div>
			}
			<Modal isOpen={this.state.delModal} toggle={() => this.toggleDelModal('')} centered>
				<ModalBody>
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 10}}>
						<i class="fa-regular fa-trash" style={{fontSize: 40, color: '#d12a5e'}}></i>
						<p style={{marginTop: 30, fontSize: 15}}>Weet je zeker dat je bestelling: #{this.state.delId} wilt verwijderen?</p>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button className="cancel_btn" onClick={() => this.toggleDelModal('')}>Sluit venster</Button>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.deleteOrder()}>Verwijderen</Button>
				</ModalFooter>				
			</Modal>
			<Modal isOpen={this.state.noteModal} toggle={() => this.toggleNoteModal('','')} centered>
				<ModalBody>
					<FormGroup>
						<label htmlFor="staticEmail" className="col-form-label">Maak een notitie</label>
						<InputGroup>
							<Input type="textarea" style={{marginTop: 5, paddingTop: 10}} rows={3} placeholder="geen notitie" value={this.state.note} onChange={e => this.setState({note: e.target.value})}/>
						</InputGroup>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button className="cancel_btn" onClick={() => this.toggleNoteModal('','')}>Sluit venster</Button>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.saveNote()}>Opslaan</Button>
				</ModalFooter>				
			</Modal>			
			<Modal isOpen={this.state.msgModal} toggle={() => this.toggleMsgModal('','','', 0)} centered>
				<ModalBody>
					{this.state.msgStatus === 0 ?
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 10}}>
						<i class="fa-regular fa-bell" style={{fontSize: 40, color: '#d12a5e'}}></i>
						<p style={{marginTop: 30, fontSize: 15, textAlign: 'center'}}>Weet je zeker dat je een afhaalbericht wilt sturen voor bestelling: #{this.state.msgId}?</p>
					</div>
					:null}
					{this.state.msgStatus === 1 ?
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 10}}>
						<div class="loading-spinner" style={{width: 45, height: 45, borderWidth: 4}}></div>
						<p style={{marginTop: 30, fontSize: 15, textAlign: 'center'}}>Afhaalbericht versturen voor bestelling: #{this.state.msgId}</p>
					</div>
					:null}
					{this.state.msgStatus === 2 ?
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 10}}>
						<i class="fa-light fa-exclamation-triangle" style={{fontSize: 40, color: '#d12a5e'}}></i>
						<p style={{marginTop: 30, fontSize: 15, textAlign: 'center'}}>Gebruiker heeft notificaties niet geaccepteerd</p>
					</div>
					:null}
					{this.state.msgStatus === 3 ?
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 10}}>
						<i class="fa-light fa-exclamation-triangle" style={{fontSize: 40, color: '#d12a5e'}}></i>
						<p style={{marginTop: 30, fontSize: 15, textAlign: 'center'}}>Afhaalbericht kon niet worden verstuurd</p>
					</div>
					:null}
				</ModalBody>
				<ModalFooter>
					<Button className="cancel_btn" onClick={() => this.toggleMsgModal('','','', 0)}>Sluit venster</Button>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.sendNotification()}>Versturen</Button>
				</ModalFooter>				
			</Modal>			
		</div>
	);
}

state = {
	orders: [],  
	loaded: false,
	delModal: false,
	noteModal: false,
	msgModal: false,
	delId: '',
	orderInd: '',
	itemInd: '',
	note: '',
	user: '',
	msgId: '',
	msgStatus: 0,
}

orderRef = '';
toggleDelModal = (id) => this.setState({'delModal': !this.state.delModal, delId: id});
toggleNoteModal = (index, index2) => this.setState({'noteModal': !this.state.noteModal, orderInd: index, itemInd: index2, note: ''});
toggleMsgModal = (index, user, oid, status) => this.setState({'msgModal': !this.state.msgModal, user: user, msgId: oid, msgStatus: status, orderInd: index});
componentDidMount(){setTimeout(() => {this.GetOrders()}, 500)}

GetOrders = () => {
	if(this.props.observableStore.oid !== '' && this.props.observableStore.currEvent !== '' && this.props.observableStore.apid !== ''){
		this.orderRef = rtdb.ref('bereidingen/' + this.props.observableStore.oid + '/' + this.props.observableStore.currEvent + '/' + this.props.observableStore.apid);
		this.orderRef.orderByChild('tijd').on('value', (snapshot) => {
			var orders = []; 
			snapshot.forEach(order => {var o = order.val(); o.id = order.key; orders.push(o);}); 
			this.setState({loaded: true, orders: orders});
		});
	}
	else{
		this.setState({loaded: true});
	}
}

formatTime(index){
	var d = new Date(this.state.orders[index].tijd);
	return ("0" + d.getHours()).slice(-2) + ':' + ("0" + d.getMinutes()).slice(-2);	
}

deleteOrder(){
	this.orderRef.child(this.state.delId).remove();
	if(this.state.orders.length === 1){this.setState({orders: []})}
	this.toggleDelModal('');
}

saveNote(){
	var orders = this.state.orders;
	orders[this.state.orderInd].items[this.state.itemInd].notitie = this.state.note;
	this.setState({orders: orders});
	this.orderRef.update({[orders[this.state.orderInd].id]: orders[this.state.orderInd]})
	this.toggleNoteModal('','')
}

sendNotification(){
	this.setState({msgStatus: 1});
	var sendNotfFunc = functions.httpsCallable('updateOrder');	
	sendNotfFunc({uid: this.state.user, oid: this.state.msgId, eid: this.props.observableStore.currEvent}).then(result => {		
		if(result.data.text === 'verstuurd'){
			var order = JSON.parse(JSON.stringify(this.state.orders[this.state.orderInd]))
			order.notified = (order.notified+1) || 1;
			this.orderRef.update({[order.id]: order});
			this.toggleMsgModal('','','', 0);
		}
		else if(result.data.text === 'notificaties niet geaccepteerd'){
			this.setState({msgStatus: 2});
		}
		else{this.setState({msgStatus: 3})}
	}).catch(err => {this.setState({msgStatus: 3})});
}

markItem(index, index2){
	var orders = this.state.orders;
	var itemCounter = 0;
	orders[index].items.forEach((item, index3) => {
		if(index3 === index2){item.marked = !item.marked}
		if(item.marked){itemCounter++}
	})
	if(itemCounter === orders[index].items.length){
		orders[index].klaar = true;
	}
	else{
		orders[index].klaar = false;
	}
	this.setState({orders: orders});
	this.orderRef.update({[orders[index].id]: orders[index]})
}

}

export default Bestellingen;