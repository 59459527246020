import React from 'react';
import { Provider } from 'mobx-react';
import observableStore from './components/stateManagement/store';
import Loginroute from './routes/loginroute';

class App extends React.Component {	
    render() {
        return (
			<Provider observableStore={observableStore}>
				<Loginroute />
			</Provider>
        )    
    }
}

export default App;