import { action, observable } from 'mobx';
import * as mobx from 'mobx';
import { auth, fbauth, db } from '../firebase/firebase';

class ObservableStore {
	
	//--------------------------------------------------------
	//---------------------  CATERAAR ------------------------
	//--------------------------------------------------------		
	
	@observable uid = '';	
	@observable mail = '';	
	
	@action setLoginInfo(uid, mail) {
		this.mail = mail;
		this.uid = uid;
		this.setEvent();
	}
	
	@action updateAcc = (mode, oldpass, newpass, newmail) => {	
		var reauthenticate = (currentPassword) => {
			var cred = fbauth.EmailAuthProvider.credential(auth.currentUser.email, currentPassword);
			return auth.currentUser.reauthenticateWithCredential(cred);
		}		
		if(mode === 'mail'){
			reauthenticate(oldpass).then(() => {
				auth.currentUser.updateEmail(newmail).then(() => {alert('Account info is aangepast')});	
			}).catch(() => {alert('Je huidige wachtwoord is incorrect')});	
		}
		if(mode === 'pass'){
			reauthenticate(oldpass).then(() => {
				auth.currentUser.updatePassword(newpass).then(() => {alert('Account info is aangepast')});	
			}).catch(() => {alert('Je huidige wachtwoord is incorrect')});	
		}
	}
	
	//--------------------------------------------------------
	//------------------------ EVENTS ------------------------
	//--------------------------------------------------------		
	
	@observable events = {};
	@observable currEvent = '';
	
	@observable oid = '';
	@observable apid = '';
	@observable directPublish = false;
	@observable ename = '';
	@observable menus = [];
	@observable norights = false;
	
	@action setEvent() {
		db.collection("cateraars").doc(this.uid).get().then(doc => {
			if(doc.exists && Object.keys(doc.data().events).length > 0){
				this.events = doc.data().events;
				this.currEvent = doc.data().currEvent;
				if(this.currEvent in this.events){
					this.oid = doc.data().events[this.currEvent].oid;
					this.apid = doc.data().events[this.currEvent].apid;				
					this.directPublish = doc.data().events[this.currEvent].directPublish;
					this.ename = doc.data().events[this.currEvent].ename;
					this.menus = doc.data().events[this.currEvent].menus;
				}
			}
			else{this.norights = true}
		}).catch(() => {this.norights = true});
	}
	
	//--------------------------------------------------------
	//------------------------ CLEARDATA ------------------------
	//--------------------------------------------------------	
	
	@action clearData() {
		this.uid = '';
		this.mail = '';
		this.oid = '';
		this.eid = '';
		this.apid = '';
		this.enaam = '';
		this.directPublish = false;
	}
	
}

const observableStore = new ObservableStore();
export default observableStore;
