import React from "react";
import classnames from 'classnames';
import { Col, Row, Table} from 'reactstrap';	
import { PDFDownloadLink } from '@react-pdf/renderer';
import { observer, inject } from 'mobx-react';
import { rtdb, db } from '../components/firebase/firebase';
import AfhaalPdf from '../components/pdf/afhaalpdf';

@inject('observableStore')
@observer

class Stats extends React.Component {
	
	state = {dateMode: 'uren', afhaalStats: {}, tokenValue: 0}	
	
	componentDidMount = () => {
		if(this.props.observableStore.currEvent !== ''){			
			var statPromises = [];
			var startDate = new Date(new Date().getFullYear(), 0, 1);
			var endDate = new Date(new Date().getFullYear(), 11, 31);
			for(var dt=new Date(startDate); dt<=endDate; dt.setDate(dt.getDate()+1)){
				const formatedTime = new Date(dt).getFullYear() + '-' + ("0" + (new Date(dt).getMonth() + 1)).slice(-2) + '-' + ("0" + new Date(dt).getDate()).slice(-2);
				statPromises.push(rtdb.ref('/stats/' + this.props.observableStore.oid + '/' + this.props.observableStore.currEvent + '/' + formatedTime + '/afhaalpunten/' + this.props.observableStore.apid).once('value'));
			}
			Promise.all(statPromises).then((vals) => {this.setStats(vals)});
			db.collection("events").doc(this.props.observableStore.currEvent).get().then(doc => {this.setState({tokenValue: doc.data().muntprijs})})
		}
	}
	
	setStats(vals){	
		var afhaalStats = {transacties: 0, tokens: 0, items: {}, btw: 0, btwh: 0, btwl: 0};
		vals.forEach(snapshot => {						
			if(snapshot.val()){
				afhaalStats.transacties = afhaalStats.transacties + snapshot.val().transacties;	
				Object.keys(snapshot.val().items).forEach(item => {
					if(afhaalStats.items && item in afhaalStats.items){
						afhaalStats.items[item].aantal = afhaalStats.items[item].aantal + snapshot.val().items[item].aantal;
						afhaalStats.items[item].tokens = afhaalStats.items[item].tokens + snapshot.val().items[item].tokens;
						afhaalStats.items[item].btw = snapshot.val().items[item].btw;
					}
					else{
						afhaalStats.items[item] = {};
						afhaalStats.items[item].aantal = snapshot.val().items[item].aantal;
						afhaalStats.items[item].tokens = snapshot.val().items[item].tokens;
						afhaalStats.items[item].btw = snapshot.val().items[item].btw;
					}
					afhaalStats.tokens = afhaalStats.tokens + snapshot.val().items[item].tokens;					
					afhaalStats.btw = afhaalStats.btw + snapshot.val().items[item].tokens / (1 + snapshot.val().items[item].btw) * snapshot.val().items[item].btw;					
					if(snapshot.val().items[item].btw === 0.09){
						afhaalStats.btwl = afhaalStats.btwl + snapshot.val().items[item].tokens / (1 + snapshot.val().items[item].btw) * snapshot.val().items[item].btw;
					}
					else{
						afhaalStats.btwh = afhaalStats.btwh + snapshot.val().items[item].tokens / (1 + snapshot.val().items[item].btw) * snapshot.val().items[item].btw;
					}
				});
			}			
		});	
		this.setState({afhaalStats: afhaalStats});	
	}	
	
	render() {
		return (
			<div style={{paddingLeft: 10, paddingRight: 10, paddingBottom: 100}}>
				<Row style={{marginTop: 20}}>
					<Col lg="12">
						<h2 className="font-light text-muted topTitle" style={{fontSize: 28, fontWeight: 900, color: '#212529'}}>Statistieken</h2>
						<div style={{marginRight: 15, marginTop: 0, width: '100%', display: 'flex', justifyContent: 'flex-end'}}>										
							<div style={{display: 'flex'}}>
								<PDFDownloadLink className="uitdraaiBtn" document={
									<AfhaalPdf data={this.state.afhaalStats.items ? this.state.afhaalStats.items : []} 
										totaalaantal={this.state.afhaalStats ? this.state.afhaalStats.transacties : '0'} 
										totaaltokens={this.state.afhaalStats ? this.state.afhaalStats.tokens : '0'} 
										totaalomzet={this.state.afhaalStats ? (this.state.afhaalStats.tokens  * this.state.tokenValue) : 0} 
										tokenvalue={this.state.tokenValue}	
										eventname={this.props.observableStore.ename}
										afhaalpunt={this.props.observableStore.apid}
										btw={this.state.afhaalStats.btw * this.state.tokenValue}
										periode={'all'}
									/>
								} fileName={"uitdraai_partypay_all.pdf"}><i class="fa-regular fa-arrow-to-bottom" style={{color: '#525f7f'}}></i></PDFDownloadLink>
							</div>
						</div>
					</Col>
				</Row>
				<Row style={{paddingTop: 25, paddingBottom: 200}}>	
					<Col lg={8}>
						<div className="graph-display" style={{paddingTop: 10}}>
							<div style={{display: 'flex', justifyContent: 'space-between', paddingTop: 10}}>
								<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, color: '#212529',}}>Items</h2>
							</div>	
							{this.state.afhaalStats.items && Object.entries(this.state.afhaalStats.items).length > 0 ? 
								<Table className="v-middle" responsive style={{marginTop: 20}}>								
								<thead>
									<tr>
										<td className="stats-td" style={{width: '40%', fontSize: 15, fontWeight: 900, color: '#595959'}}>Item</td>
										<td className="stats-td" style={{fontSize: 15, fontWeight: 900, color: '#595959'}}>Stuks</td>
										<td className="stats-td" style={{fontSize: 15, fontWeight: 900, color: '#595959'}}>Tokens</td>
										<td className="stats-td" style={{fontSize: 15, fontWeight: 900, color: '#595959'}}>Omzet</td>
									</tr>
								</thead>
								<tbody>
									{Object.entries(this.state.afhaalStats.items).filter(item => item[1].aantal > 0).sort((a, b) => { return b[1].tokens - a[1].tokens }).map((item, index) => (											
										<tr style={{background: index%2 !== 0 ? null : 'rgba(0,0,0,0.03)'}}>
											<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{item[0]}</h6></td>
											<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{item[1].aantal}</h6></td>
											<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{item[1].tokens}</h6></td>
											<td className="stats-td"><h6 style={{maxWidth: 200, background: '#43A047', fontWeight: 500, minWidth: 100, textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 7}}>€ {((item[1].tokens * this.state.tokenValue).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}))}</h6></td>
										</tr>	
									))}
								</tbody>							
								</Table>
							:
								<div style={{top: 0, height: 330, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center'}}>Er zijn nog geen items gescanned</div>
							}	
						</div>													  
					</Col>
					<Col lg={4}>
						<Row>
							<Col lg={12}>
								<div className="graph-display graph-display-right" style={{paddingTop: 10}}>
									<div style={{display: 'flex', justifyContent: 'space-between', paddingTop: 10}}>
										<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, color: '#212529',}}>Totaal</h2>
									</div>	
									{this.state.afhaalStats.items && Object.entries(this.state.afhaalStats.items).length > 0 ? 
										<Table className="v-middle" responsive style={{marginTop: 20}}>							
											<thead>
												<tr>
													<td className="stats-td" style={{fontSize: 15, fontWeight: 900, color: '#595959'}}>Bestellingen</td>
													<td className="stats-td" style={{fontSize: 15, fontWeight: 900, color: '#595959'}}>Tokens</td>
													<td className="stats-td" style={{fontSize: 15, fontWeight: 900, color: '#595959'}}>Omzet</td>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td style={{width: '25%', paddingTop: 15, borderWidth: 0}}><h6 style={{fontSize: 17, fontWeight: 500}}>{this.state.afhaalStats ? this.state.afhaalStats.transacties : '0'}</h6></td>
													<td style={{width: '25%', paddingTop: 15, borderWidth: 0}}><h6 style={{fontSize: 17, fontWeight: 500}}>{this.state.afhaalStats ? this.state.afhaalStats.tokens : '0'}</h6></td>
													<td style={{width: '25%', paddingTop: 15, borderWidth: 0}}><h6 style={{fontSize: 20, fontWeight: 900, borderRadius: 5, color: '#d12a5e', padding: 8, paddingTop: 9}}>€ {this.state.afhaalStats ? (this.state.afhaalStats.tokens  * this.state.tokenValue).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}</h6></td>
												</tr>
											</tbody>							
										</Table>
									:
										<div style={{top: 0, height: 105, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center'}}>Er zijn nog geen items gescanned</div>
									}	
								</div>	
							</Col>
						</Row>
						<Row style={{paddingTop: 25, paddingBottom: 200}}>
							<Col lg={12}>
								<div className="graph-display" style={{paddingTop: 10}}>
									<div style={{display: 'flex', justifyContent: 'space-between', paddingTop: 10}}>
										<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, color: '#212529',}}>BTW</h2>
									</div>	
									{this.state.afhaalStats.items && Object.entries(this.state.afhaalStats.items).length > 0 ? 
										<Table className="v-middle" responsive style={{marginTop: 20}}>								
										<thead>
											<tr>
												<td className="stats-td" style={{fontSize: 15, fontWeight: 900, color: '#595959'}}>BTW (9%)</td>
												<td className="stats-td" style={{fontSize: 15, fontWeight: 900, color: '#595959'}}>BTW (21%)</td>
												<td className="stats-td" style={{fontSize: 15, fontWeight: 900, color: '#595959'}}>Totaal</td>
											</tr>
										</thead>									
										<tbody>
											<tr>
												<td style={{width: '25%', paddingTop: 15, borderWidth: 0}}><h6 style={{fontSize: 17, fontWeight: 500}}>€ {this.state.afhaalStats ? (this.state.afhaalStats.btwl * this.state.tokenValue).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}</h6></td>
												<td style={{width: '25%', paddingTop: 15, borderWidth: 0}}><h6 style={{fontSize: 17, fontWeight: 500}}>€ {this.state.afhaalStats ? (this.state.afhaalStats.btwh * this.state.tokenValue).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}</h6></td>
												<td style={{width: '25%', paddingTop: 15, borderWidth: 0}}><h6 style={{fontSize: 20, fontWeight: 900, borderRadius: 5, color: '#d12a5e', padding: 8, paddingTop: 9}}>€ {this.state.afhaalStats ? (this.state.afhaalStats.btw * this.state.tokenValue).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'}</h6></td>
											</tr>
										</tbody>								
										</Table>
									:
										<div style={{top: 0, height: 105, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center'}}>Er zijn nog geen items gescanned</div>
									}
								</div>	
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		);
	}	
}

export default Stats;