import React from "react";
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import moment from "moment";
import logo from "../../assets/images/pplogo.jpg";
import "moment/locale/nl";

function AfhaalPdf(props) {
	return (
		<Document>			
			<Page style={{padding: '15px 75px 15px 75px', fontWeight: 700}}>
				<View style={{width: '100%', justifyContent: 'center', paddingTop: 50, paddingBottom: 40}} fixed>
					<Image src={logo} style={{width: 111, height: 85}} alt="logo" />
				</View>	
				<View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'flex-end', fontSize: 12}}>			
					<View>
						<View style={{paddingVertical: 2}}><Text style={{fontSize: 15, color: '#212121'}}>Uitdraai omzet</Text></View>
						<View style={{marginTop: 8}}><Text style={{color: '#444444'}}>{props.eventname}</Text></View>	
						<View style={{paddingVertical: 2}}><Text style={{color: '#444444'}}>{props.periode}</Text></View>
					</View>	
				</View>					
				<View style={{flexDirection: 'row', marginTop: 50, color: '#555555', fontSize: 12, paddingVertical: 12, justifyContent: 'space-between'}}>
					<View style={{width: '40%'}}>
						<Text>Onderdeel</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>Transacties</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>Tokens</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>Omzet</Text>
					</View>
				</View>	
				<View style={{borderBottomWidth: 0.5, borderTopWidth: 0.5, paddingBottom: 9, borderColor: '#878787'}}>
					<View style={{flexDirection: 'row', color: '#212121', fontSize: 12, marginTop: 10, paddingVertical: 18, justifyContent: 'space-between'}}>
						<View style={{width: '40%'}}>
							<Text>Afhaalpunt verkoop</Text>
						</View>
						<View style={{width: '15%', textAlign: 'right'}}>
							<Text>{props.totaalaantal}</Text>
						</View>
						<View style={{width: '20%', textAlign: 'right'}}>
							<Text>{props.totaaltokens}</Text>
						</View>
						<View style={{width: '20%', textAlign: 'right'}}>
							<Text>€ {props.totaalomzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
						</View>
					</View>
				</View>
				<View style={{flexDirection: 'row', fontSize: 14, marginTop: 40, paddingVertical: 10, justifyContent: 'space-between'}}>
					<View style={{width: '40%'}}></View>
					<View style={{width: '30%'}}>
						<Text>Subtotaal</Text>
					</View>
					<View style={{width: '30%', textAlign: 'right'}}>
						<Text>€ {(props.totaalomzet - props.btw).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>				
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 12, paddingVertical: 16, justifyContent: 'space-between'}}>
					<View style={{width: '40%'}}></View>
					<View style={{width: '30%'}}>
						<Text>BTW</Text>
					</View>
					<View style={{width: '30%', textAlign: 'right'}}>
						<Text>€ {props.btw.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>
				<View style={{flexDirection: 'row', color: '#d12a5e', fontSize: 18, justifyContent: 'space-between'}}>
					<View style={{width: '40%'}}></View>
					<View style={{width: '30%', paddingVertical: 18, borderTopWidth: 0.5, borderColor: '#d12a5e'}}>
						<Text>Totaal</Text>
					</View>
					<View style={{width: '30%', paddingVertical: 18, textAlign: 'right', borderTopWidth: 0.5, borderColor: '#d12a5e'}}>
						<Text>€ {props.totaalomzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>
				<View style={{width: '100%', height: 25}} fixed></View>				
			</Page>
			
			<Page style={{padding: '15px 75px 15px 75px', fontWeight: 700}}>
				<View style={{width: '100%', justifyContent: 'center', paddingTop: 50, paddingBottom: 40}} fixed>
					<Image src={logo} style={{width: 111, height: 85}} alt="logo" />
				</View>	
				<View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'flex-end', fontSize: 12}}>			
					<View>
						<View style={{paddingVertical: 2}}><Text style={{fontSize: 15, color: '#212121'}}>Uitdraai afhaalpunt</Text></View>
						<View style={{marginTop: 8}}><Text style={{color: '#444444'}}>{props.eventname}</Text></View>	
						<View style={{paddingVertical: 2}}><Text style={{color: '#444444'}}>{props.periode}</Text></View>
					</View>	
				</View>								
				<View style={{marginTop: 30}}>
					<View style={{flexDirection: 'row', color: '#555555', fontSize: 12, marginTop: 10, paddingVertical: 12, justifyContent: 'space-between', borderBottomWidth: 0.5, borderColor: '#878787'}}>
						<View style={{width: '30%'}}>
							<Text>Omschrijving</Text>
						</View>
						<View style={{width: '15%', textAlign: 'right'}}>
							<Text>aantal</Text>
						</View>
						<View style={{width: '15%', textAlign: 'right'}}>
							<Text>tokens</Text>
						</View>
						<View style={{width: '20%', textAlign: 'right'}}>
							<Text>Omzet</Text>
						</View>
						<View style={{width: '20%', textAlign: 'right'}}>
							<Text>BTW</Text>
						</View>
					</View>
					{props.data ? Object.entries(props.data).filter(item => item[1].aantal > 0).map((item, index) => (
						<View style={{flexDirection: 'row', fontSize: 12, paddingHorizontal: 8, paddingVertical: 4, justifyContent: 'space-between', backgroundColor: index%2 !== 0 ? null : 'rgba(0,0,0,0.04)'}}>
							<View style={{width: '30%'}}>
								<Text>{item[0]}</Text>
							</View>
							<View style={{width: '15%', textAlign: 'right'}}>
								<Text>{item[1].aantal}</Text>
							</View>
							<View style={{width: '15%', textAlign: 'right'}}>
								<Text>{item[1].tokens}</Text>
							</View>						
							<View style={{width: '20%', textAlign: 'right'}}>
								<Text>€ {((item[1].tokens * props.tokenvalue).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}))}</Text>
							</View>
							<View style={{width: '20%', textAlign: 'right'}}>
								<Text>€ {(((item[1].tokens * props.tokenvalue) / (1 + item[1].btw) * item[1].btw).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}))}</Text>
							</View>
						</View>
						)):null}
					</View>
				<View style={{width: '100%', height: 25}} fixed></View>
			</Page>
		</Document>
	);
}

export default AfhaalPdf;