import Bestellingen from '../views/bestellingen';
import Instellingen from '../views/instellingen';
import Menu from '../views/menu';
import Stats from '../views/stats';
import Logout from '../views/logout';

var ThemeRoutes = [
	{
		path: "/event-bestelling",
		name: "Bestellingen",
		mini: "B",
		icon: "fa-light fa-store",
		component: Bestellingen
	},
	{
		path: "/event-menu",
		name: "Assortiment",
		mini: "B",
		icon: "fa-light fa-hamburger",
		component: Menu
	},
	{
		path: "/event-stats",
		name: "Statistieken",
		mini: "B",
		icon: "fa-light fa-chart-mixed",
		component: Stats
	},
	{ 	path: '/event-instellingen',
		name: 'Instellingen', 
		icon: 'fa-light fa-gear', 
		component: Instellingen 
	},
	{ 	path: '/Uitloggen',
		name: 'Uitloggen', 
		icon: 'fa-light fa-door-open', 
		component: Logout 
	},
	{ path: '/', pathTo: '/event-bestelling', name: 'Assortiment', redirect: true }
];
export default ThemeRoutes;
