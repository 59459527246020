import React from 'react';
import { auth } from '../components/firebase/firebase';
import { observer, inject } from 'mobx-react';

@inject('observableStore')
@observer

class FormInputs extends React.Component {	   
	componentDidMount = () => {
		this.props.observableStore.clearData();
		auth.signOut();
	}  
  render() {	
     return '';
  }
}

export default FormInputs;


