import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {Nav, Button, Navbar, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input,} from 'reactstrap';
import Sidebar from './sidebar';
import { auth } from '../components/firebase/firebase';
import ThemeRoutes from '../routes/router';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Warning from '../assets/images/pp_warning.svg';

@inject('observableStore')
@observer

class Fulllayout extends React.Component {
	constructor(props) {
		super(props);
		this.updateDimensions = this.updateDimensions.bind(this);
		this.state = {
			width: window.innerWidth,
			activeSidebarType: "full",
		};

		this.props.history.listen((location, action) => {
			if (window.innerWidth < 767 &&
				document.getElementById('main-wrapper').className.indexOf("show-sidebar") !== -1) {
				document.getElementById('main-wrapper').classList.toggle("show-sidebar");
			}
		});
	}
	
	showMobilemenu() {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	}
	
	sidebarHandler = () => {
		let element = document.getElementById('main-wrapper');
		switch (this.state.activeSidebarType) {
			case 'full':
			case 'iconbar':
				element.classList.toggle('mini-sidebar');
				if (element.classList.contains('mini-sidebar')) {
					element.setAttribute('data-sidebartype', 'mini-sidebar');					
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.state.activeSidebarType
					);					
				}
				break;

			case 'overlay':
			case 'mini-sidebar':
				element.classList.toggle('full');
				if (element.classList.contains('full')) {
					element.setAttribute('data-sidebartype', 'full');					
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.state.activeSidebarType
					);					
				}
				break;
			default:
		}
	};

	logout = () => {
		this.props.observableStore.clearData();
		auth.signOut();
	}
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook, Applies when loading or resizing App                           */
	/*--------------------------------------------------------------------------------*/
	componentDidMount() {
		window.addEventListener("load", this.updateDimensions());
		window.addEventListener("resize", this.updateDimensions());
	}
	/*--------------------------------------------------------------------------------*/
	/*Function that handles sidebar, changes when resizing App                        */
	/*--------------------------------------------------------------------------------*/
	updateDimensions() {
		let element = document.getElementById('main-wrapper');
		this.setState({
			width: window.innerWidth
		});
		switch (this.state.activeSidebarType) {
			case 'full':
			case 'iconbar':
				if (this.state.width < 2500) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
					element.classList.add("mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.state.activeSidebarType);
					element.classList.remove("mini-sidebar");
				}
				break;

			case 'overlay':
				if (this.state.width < 767) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.state.activeSidebarType);
				}
				break;

			default:
		}
	}
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook                                                                 */
	/*--------------------------------------------------------------------------------*/
	componentWillUnmount() {
		window.removeEventListener("load", this.updateDimensions);
		window.removeEventListener("resize", this.updateDimensions);
	}
	render() {
		/*--------------------------------------------------------------------------------*/
		/* Theme Setting && Layout Options wiil be Change From Here                       */
		/*--------------------------------------------------------------------------------*/
		return (
			<div
				id="main-wrapper"
				dir={"ltr"}
				data-theme={"light"}
				data-layout={"vertical"}
				data-sidebartype={this.state.activeSidebarType}
				data-sidebar-position={"fixed"}
				data-header-position={"fixed"}
				data-boxed-layout={"full"}
			>
				{/*--------------------------------------------------------------------------------*/}
				{/* Sidebar                                                                        */}
				{/*--------------------------------------------------------------------------------*/}
				<Sidebar {...this.props} routes={ThemeRoutes} />
				{/*--------------------------------------------------------------------------------*/}
				{/* Page Main-Content                                                              */}
				{/*--------------------------------------------------------------------------------*/}
				<div className="page-wrapper d-block">
					<div style={{width: '100%', position: 'absolute', zIndex: 10, top: 10}} className="mainHead">
						<div style={{marginLeft: 'auto', marginRight: 'auto'}}>
							<Nav className="float-left" navbar>
								<div onClick={ () => {this.showMobilemenu()}} className="mobileMenu" style={{paddingLeft: 45, display: 'none'}}><i className="fa-light fa-bars" style={{fontSize: 26, marginLeft: -30}}/></div>
							</Nav>
						</div>
					</div>
					<div className="page-content container-fluid">
						<Switch>
							{ThemeRoutes.map((prop, key) => {
								if (prop.navlabel) {
									return null;
								}
								else if (prop.collapse) {
									return prop.child.map((prop2, key2) => {
										if (prop2.collapse) {
											return prop2.subchild.map((prop3, key3) => {
												return (
													<Route path={prop3.path} component={prop3.component} key={key3} />
												);
											});
										}
										return (
											<Route path={prop2.path} component={prop2.component} key={key2} />
										);
									});
								}
								else if (prop.redirect) {
									return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
								}
								else {
									return (
										<Route path={prop.path} component={prop.component} key={key} />
									);
								}
							})}
						</Switch>
					</div>
				</div>
				<Modal isOpen={this.props.observableStore.norights} className={this.props.className} centered >
					<ModalBody style={{padding: 35, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
						<img src={Warning} style={{width: 50, marginBottom: 15}} />
						<p style={{marginTop: 10}}>Geen rechten voor dit dashboard</p>
					</ModalBody>
					<ModalFooter>
						<Button style={{backgroundColor: '#d12a5e', border: 0, marginTop: 25}} onClick={() => this.logout()}>Uitloggen</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}
export default Fulllayout;
