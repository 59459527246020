import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';

var config = {
	apiKey: "AIzaSyCWKCO99Pl0cUq4FBOGRYPxFjNNsXCZu5I",
	authDomain: "partypay-a3d12.firebaseapp.com",
	databaseURL: "https://partypay-a3d12.firebaseio.com",
	projectId: "partypay-a3d12",
	storageBucket: "partypay-a3d12.appspot.com",
	messagingSenderId: "286561888158",
	appId: "1:286561888158:web:21e057517e44b598"
};
if (!firebase.apps.length) {
	firebase.initializeApp(config);
}
const db = firebase.firestore();
const auth = firebase.auth();
const fbauth = firebase.auth;
const storage = firebase.storage();
const rtdb = firebase.database();
const functions = firebase.functions();

export {
	db,
	auth,
	fbauth,
	storage,
	rtdb,
	functions,
};

