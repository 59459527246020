import React from 'react';
import {Row, Col, Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Input, InputGroup, InputGroupAddon, InputGroupText, Button } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { db } from '../components/firebase/firebase';
import PasswordStrengthBar from 'react-password-strength-bar';

@inject('observableStore')
@observer

class Instellingen extends React.Component {	
  
	state = {
		settingsCat: 'account',
		apManageEvent: '',
		saveModal: false,
		accModal: false,
		infoModal: false,
		infoModalTxt: '',
		currPassModal: false,
		oldpassword: '',
		password: '',
		passwordRepeat: '',
		mail: this.props.observableStore.mail,
		changeMode: 'mail'
	}
	
	setEvent(){
		if(this.state.apManageEvent !== ''){
			this.props.observableStore.currEvent = this.state.apManageEvent;
			db.collection("cateraars").doc(this.props.observableStore.uid).update({currEvent: this.state.apManageEvent});	
			this.toggleSaveModal();		
		}	
	}
	
	toggleSaveModal = () => this.setState({'saveModal': !this.state.saveModal});
	toggleAccModal = () => this.setState({'accModal': !this.state.accModal});
	toggleInfoModal = (error) => this.setState({'infoModal': !this.state.infoModal, 'infoModalTxt': error});
	toggleCurrPassModal = (changeMode) => this.setState({'currPassModal': !this.state.currPassModal, 'changeMode': changeMode});
	
	saveOrganisatieInfo = () => {
		this.props.observableStore.setOrganisatieInfo(false);
		this.toggleAccModal();
	}
	
	checkMail(){
		if(!this.ValidateEmail(this.state.mail)){this.toggleInfoModal('Voer a.u.b. een valide email address in')}
		else{this.toggleCurrPassModal('mail')}
	}
	
	checkPass(){
		if(this.state.passwordSecure < 3){this.toggleInfoModal('Voer a.u.b. een sterk wachtwoord in')}
		else if(this.state.password !== this.state.passwordRepeat){this.toggleInfoModal('De wachtwoorden komen niet overeen')}
		else{this.toggleCurrPassModal('pass')}
	}
		
	updateAcc(){
		this.props.observableStore.updateAcc(this.state.changeMode, this.state.oldpassword, this.state.password, this.state.mail);
		this.toggleCurrPassModal();
	}
	
	ValidateEmail = (mail) => {
		// eslint-disable-next-line
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){return true;}
		return false;
	}
  
  render() {
    return(
		<div style={{paddingLeft: 10, paddingRight: 10, paddingBottom: 100, marginTop: 20}}>
			<Row>
				<Col lg={12}>
					<h2 className="font-light text-muted topTitle" style={{fontSize: 28, fontWeight: 900, color: '#212529'}}>Instellingen</h2>
				</Col>
			</Row>
			<Row style={{marginTop: 40, paddingLeft: 15, paddingRight: 15}}>
				<Col lg={2} className="graph-display" style={{background: 'none', paddingTop: 0, paddingRight: 0, paddingLeft: 0, minWidth: 240}}>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'account' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'account' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'account'})}>
						<span>Account</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.settingsCat === 'events' ? '#f6f6f6' : '', borderLeftWidth: this.state.settingsCat === 'events' ? 6 : 0}} onClick={() => this.setState({settingsCat: 'events'})}>
						<span>Evenementen</span>
					</div>
				</Col>				
				
				<Col className="graph-display graphSettings" style={{marginLeft: 20, padding: 40, paddingTop: 30, overflow: 'visible'}}>					
					
					{this.state.settingsCat === 'account' ?
					<Form className="mt-1">
						<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, fontWeight: 900, color: '#212529',}}>Account info Wijzigen</h2>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 30}}></div>
						<Row>
							<Col lg={6}>
								<div style={{maxWidth: 450}}>
									<h2 className="font-light text-muted" style={{fontSize: 18, color: '#212529',}}>Wijzig e-mailadres</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15, marginBottom: 20}}></div>
									<FormGroup>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 125, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>E-mailadres</InputGroupText>
											</InputGroupAddon>
											<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} placeholder="" value={this.state.mail} onChange={e => this.setState({mail: e.target.value})}/>
										</InputGroup>
										<div style={{marginTop: 25, width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
											<div className="addBtnLst addBtnLstSettings" style={{marginBottom: 15, cursor: 'pointer', width: 150, background: '#d12a5e', color: '#fff'}} onClick={() => this.checkMail()}>Opslaan</div>
										</div>
									</FormGroup>
									<h2 className="font-light text-muted" style={{fontSize: 18, color: '#212529',}}>Wijzig wachtwoord</h2>
									<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 15, marginBottom: 20}}></div>
									<FormGroup style={{marginBottom: 20}}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Nieuw wachtwoord</InputGroupText>
											</InputGroupAddon>
											<Input type="password" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10, marginBottom: 15}} placeholder="********" value={this.state.password} onChange={e => this.setState({password: e.target.value})}/>
										</InputGroup>
										<PasswordStrengthBar password={this.state.password} shortScoreWord="te kort" scoreWords={['zwak', 'zwak', 'gemiddeld', 'goed', 'sterk']} barColors={['#ddd', '#ef4836', '#ef4836', '#25c281', '#25c281']} onChangeScore={(score, feedback) => this.setState({passwordSecure: score})}/>
									</FormGroup>
									<FormGroup>
										<InputGroup>
											<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
												<InputGroupText style={{background: '#fff', width: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Herhaal wachtwoord</InputGroupText>
											</InputGroupAddon>
											<Input type="password" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10, marginBottom: 15}} placeholder="********" value={this.state.passwordRepeat} onChange={e => this.setState({passwordRepeat: e.target.value})}/>
										</InputGroup>
										<PasswordStrengthBar password={this.state.passwordRepeat} shortScoreWord="te kort" scoreWords={['zwak', 'zwak', 'gemiddeld', 'goed', 'sterk']} barColors={['#ddd', '#ef4836', '#ef4836', '#25c281', '#25c281']}/>
										<div style={{marginTop: 25, width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
											<div className="addBtnLst addBtnLstSettings" style={{marginBottom: 0, cursor: 'pointer', width: 150, background: '#d12a5e', color: '#fff'}} onClick={() => this.checkPass()}>Opslaan</div>
										</div>
									</FormGroup>
								</div>
							</Col>
							<Col lg={6} className="passDesc">
								<label htmlFor="inputPassword" className="col-form-label">Voer a.u.b. een wachtwoord van minimaal 7 karakters in. Wij adviseren om zowel cijfers, capitalen als speciale tekens te gebruiken.</label>
							</Col>
						</Row>
					</Form>		
					:null}
					
					{this.state.settingsCat === 'events' ?
					<Form className="mt-1">
						<div className="addBtnLst" style={{cursor: 'pointer', position: 'absolute', top: 20, right: 35, width: 150, background: '#d12a5e', color: '#fff', }} onClick={() => this.setEvent()}>Opslaan</div>
						<h2 className="font-light text-muted mobileTitle" style={{marginTop: 10, fontSize: 20, color: '#212529',}}>Evenement of locatie instellen</h2>
						<div style={{width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)', marginTop: 20, marginBottom: 30}}></div>
						<Row>
							<Col lg={5}>
								<div style={{maxWidth: 350}}>
									<FormGroup>
										<label htmlFor="staticEmail" className="col-form-label">Selecteer een evenement of locatie</label>
										<Input type="select" style={{width: '100%', marginBottom: 20}} onChange={e => this.setState({apManageEvent: e.target.value})}>
											<option value={this.props.observableStore.currEvent} selected>{this.props.observableStore.currEvent === '' ? 'Selecteer een evenement of locatie' : this.props.observableStore.events[this.props.observableStore.currEvent].ename}</option>
											{Object.keys(this.props.observableStore.events).map((evt, index) => {if(evt !== this.props.observableStore.currEvent){
												return <option value={evt}>{this.props.observableStore.events[evt].ename}</option>												
											}})}
										</Input>
									</FormGroup>
								</div>
							</Col>
						</Row>
					</Form>		
					:null}					

				</Col>
			</Row>
			<Modal isOpen={this.state.saveModal} toggle={this.toggleSaveModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggle}>Wijzigingen opslaan</ModalHeader>
				<ModalBody>
					<p style={{marginTop: 10}}>
						Wijzigingen zijn opgeslagen.
					</p>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.toggleSaveModal}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.accModal} toggle={this.toggleAccModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggle}>Wijzigingen opslaan</ModalHeader>
				<ModalBody>
					<p style={{marginTop: 10}}>
					 Wijzigingen zijn opgeslagen.
					</p>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.toggleAccModal}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.infoModal} toggle={() => this.toggleInfoModal('')} className={this.props.className} centered >
				<ModalHeader toggle={this.toggle}>Foutmelding</ModalHeader>
				<ModalBody>
					<p style={{marginTop: 10}}>{this.state.infoModalTxt}</p>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.toggleInfoModal('')}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.currPassModal} toggle={this.toggleCurrPassModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleCurrPassModal}>Authentificeren</ModalHeader>
				<ModalBody>
				<FormGroup>
					<label htmlFor="staticEmail" className="col-form-label">Vul je Huidige wachtwoord in</label>
					<InputGroup>
						<Input type="password" placeholder="********" value={this.state.oldpassword} onChange={e => this.setState({oldpassword: e.target.value})}/>
					</InputGroup>
				</FormGroup>				
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.updateAcc()}>
						Account info wijzigen
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.toggleCurrPassModal}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	)
  }
}

export default Instellingen;
